import React, { useEffect, useRef, useState } from 'react';
import "./styles/Support.css"
import { useNavigate } from 'react-router-dom';
import { PayPalButtons } from '@paypal/react-paypal-js';
import PaypalButton from '../components/layout/PaypalButton';

function Support() {
    const navigate = useNavigate();
    const [donation, setDonation] = useState<number>(200);
    const donationRef = useRef(donation);

    const goToBook = ()=> {
        navigate("/book")
    }
    useEffect(()=>{donationRef.current = donation}, [donation]);
    
    return (
        <>
            <div className="content-body support-page">
                <div className="section-title">
                    <h2>Cómo apoyar</h2>
                    <hr />
                </div>
                <div className="support-card">
                    <div>
                        <p>
                            ¿Deseas apoyar a una familia?</p>
                    </div>
                    <div className="donations-card">
                        <div className="circle-up">
                        </div>
                        <div className="circle-bot"></div>
                        <p>
                            <b>
                                Nombre de la fundación:
                            </b>
                            Fundación Medicina Fetal México A.C
                        </p>
                        <p>
                            <b>
                                Banco:
                            </b>
                            BANORTE
                        </p>
                        <p>
                            <b>
                                No. Cuenta:
                            </b>
                            1219889884
                        </p>
                        <p>
                            <b>
                                CLABE:
                            </b>
                            072 320 012198898846
                        </p>
                    </div>
                    <p>
                        {"¡Contáctanos! Escribe un mail a "}
                        <a className="secondary-link" href="mailto:donacionesfundacion@medicinafetalmexico.com?Subject=Nueva%20Pregunta">donacionesfundacion@medicinafetalmexico.com</a>
                        {" o llámanos al 4421687879."}
                    </p>
                </div>
                
                <div className="row button-container">
                    <a className="button button-cta" href="mailto:donacionesfundacion@medicinafetalmexico.com?Subject=Nueva%20Pregunta">
                        Enviar Correo Electrónico
                    </a>
                    <a className="button button-cta" href="tel:4421957014">
                        Llamar vía Teléfono
                    </a>
                </div>
                <br />
                <div className='donation'>
                    <div>
                        <span>Apoya con la cantidad que desees (MXN)</span>
                        <input type="number" value={donation} min={1} step={50} onChange={e => setDonation(Number(e.target.value))}/>
                    </div>
                    <PaypalButton onSuccess={()=>{alert("¡¡Gracias por tu apoyo!!")}} totalValue={donation} key={donation}/>
                </div>
                <br />
                <p>
                    No olvides de seguirnos en nuestras redes sociales:
                </p>
                <div className="social-media-container">
                    <a href="https://www.facebook.com/fundacionmedfetalmexico" />
                    <img />
                    <img />
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
                    <div className="social-media-container">
                        <a href="https://www.facebook.com/fundacionmedfetalmexico">
                            <img src={process.env.PUBLIC_URL + "/img/facebook.png"} style={{ maxWidth: "20px" }} />
                        </a>
                    </div>
                    <div className="social-media-container">
                        <a href="https://www.instagram.com/fundacionmedicinafetalmexico/">
                            <img src={process.env.PUBLIC_URL + "/img/instagram.png"} style={{ maxWidth: "30px" }} />
                        </a>
                    </div>
                </div>
                <br />
                <div className='book-section'>
                    <img src={process.env.PUBLIC_URL  + "/img/libro.png"} alt="" />
                    <span>Todo lo recaudado será para cambiar vidas de mujeres embarazadas en situación de vulnerabilidad,  y asegurar la salud de sus bebés antes de nacer.</span>
                    <a className="button button-cta" onClick={goToBook} style={{cursor:"pointer"}}  >
                        Comprar
                    </a>
                </div>

                <br /><br /><br />
                <div className="section-title"><h2>Nuestros Patrocinadores</h2><hr /></div>
                <div className="images-container">
                    <img src={process.env.PUBLIC_URL + "/img/dif.png"}/>
                    <img src={process.env.PUBLIC_URL + "/img/fetalmed.png"}/>
                    <img src={process.env.PUBLIC_URL + "/img/premiumSystem.jpeg"}/>
                    <img src={process.env.PUBLIC_URL + "/img/kimberly.jpeg"}/>
                    <img src={process.env.PUBLIC_URL + "/img/defestatal.jpeg"}/>
                    <img src={process.env.PUBLIC_URL + "/img/cemefi.png"}/>
                    <img src={process.env.PUBLIC_URL + "/img/OXO.png"}/>
                </div>
            </div>
        </>
    );
}

export default Support;
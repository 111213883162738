import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './pages/Home';
import OverlayProvider from './context/OverlayProvider';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <PayPalScriptProvider options={{ clientId: 'AapUGGBGoyNBt1cGA3gVnCR3_vTvfUhwHpuz1CF3XW_VWuL_u-WMYxemrpITpaknMMtz39cWEskwo35L', currency: 'MXN', vault: 'true' }} >
      <OverlayProvider>
        <App />
      </OverlayProvider>
    </PayPalScriptProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useMemo, useRef, useState } from 'react';
import "./styles/Book.css"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PaypalButton from '../components/layout/PaypalButton';
import axios from 'axios';

function Book() {
    const [userData, setUserData] = useState({ name: "", email: "", telephone: "", address: "" });
    const userDataRef = useRef(userData);

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const onApprovePurchase = (order:any) => {
        axios.post(`https://api.web3forms.com/submit`, {"Title":"COMPRA DE LIRBO", ...userDataRef.current, ...{orderId:order.orderID }, access_key:"4dafcc71-07de-4fdd-8533-bfde9636bdf8"})
        .then(() => alert("Se envio la información, pronto nos contactaremos contigo.")).catch((error) => console.error(error));
    };

    const onCancelPurchase = (order:any) => {
        console.log("User Data at Cancellation:" + {...userDataRef.current, ...{orderId:order.orderID }});
    };
    useEffect(() => { userDataRef.current = userData; }, [userData]);
    
    return (
        <>
            <div className="content-body support-page">
                <div className='section-title shipment-data'>
                    <h2>Información de envío</h2>
                    <input type="text" name="name" placeholder="Nombre" onChange={handleChange} />
                    <input type="text" name="email" placeholder="Correo Electrónico" onChange={handleChange} />
                    <input type="text" name="telephone" placeholder="Teléfono" onChange={handleChange} />
                    <input type="text" name="address" placeholder="Dirección" onChange={handleChange} />
                </div>
                <hr />
                <div className="section-title">
                    <h2>Comprar libro</h2>
                    <div className="support-card" style={{ width: "100%" }}>
                        <div className="donations-card" style={{ display: "flex", justifyContent: "center" }}>
                            <PaypalButton _userData={userData} totalValue='1800' onCancelled={onCancelPurchase} onSuccess={onApprovePurchase} 
                            />
                        </div>
                    </div>
                </div>
                <div className="support-card">
                    <div>
                        <p>
                            Opción 2</p>
                    </div>
                    <div className="donations-card">
                        <div className="circle-up"></div>
                        <div className="circle-bot"></div>
                        <p>
                            <b>
                                Nombre de la fundación:
                            </b>
                            Fundación Medicina Fetal México A.C
                        </p>
                        <p>
                            <b>
                                Banco:
                            </b>
                            BANORTE
                        </p>
                        <p>
                            <b>
                                No. Cuenta:
                            </b>
                            1219889884
                        </p>
                        <p>
                            <b>
                                CLABE:
                            </b>
                            072 320 012198898846
                        </p>
                    </div>
                    <p>
                        ¡Contáctanos! Escribe un mail a
                        <a className="secondary-link" href="mailto:donacionesfundacion@medicinafetalmexico.com?Subject=Nueva%20Pregunta">donacionesfundacion@medicinafetalmexico.com</a>
                        o llámanos al 4421687879.
                    </p>
                </div>
                <div className="row button-container">
                    <a className="button button-cta" href="mailto:donacionesfundacion@medicinafetalmexico.com?Subject=Nueva%20Pregunta">
                        Enviar Correo Electrónico
                    </a>
                    <a className="button button-cta" href="tel:4421957014">
                        Llamar vía Teléfono
                    </a>
                </div>
                <br />
                <p>
                    No olvides de seguirnos en nuestras redes sociales:
                </p>
                <div className="social-media-container">
                    <a href="https://www.facebook.com/fundacionmedfetalmexico" />
                    <img />
                    <img />
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
                    <div className="social-media-container">
                        <a href="https://www.facebook.com/fundacionmedfetalmexico">
                            <img src={process.env.PUBLIC_URL + "/img/facebook.png"} style={{ maxWidth: "20px" }} />
                        </a>
                    </div>
                    <div className="social-media-container">
                        <a href="https://www.instagram.com/fundacionmedicinafetalmexico/">
                            <img src={process.env.PUBLIC_URL + "/img/instagram.png"} style={{ maxWidth: "30px" }} />
                        </a>
                    </div>
                </div>
                <br /><br /><br />
                <div className="section-title"><h2>Nuestros Patrocinadores</h2><hr /></div>
                <div className="images-container">
                    <img src={process.env.PUBLIC_URL + "/img/dif.png"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/fetalmed.png"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/premiumSystem.jpeg"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/kimberly.jpeg"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/defestatal.jpeg"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/cemefi.png"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/OXO.png"} style={{ width: "100%" }} />
                </div>
            </div>
        </>
    );
}

export default Book;
import { PayPalButtons } from "@paypal/react-paypal-js";

interface PaypalInterface {
    totalValue:string|number;
    onSuccess:(value:any)=>void;
    onCancelled?:(value:any)=>void;
    _userData?:any;
}

const PaypalButton : React.FC<PaypalInterface> = (props) => {
    return(
        <PayPalButtons style={{ 'color': 'blue', }} className="button-paypal" createOrder={(data, actions) => {
            return actions.order.create({
                'purchase_units': [{
                    'amount': {
                        value: props.totalValue.toString()
                    }
                }],
                "application_context": { "brand_name": "Fundación Medicina Fetal México" }
            })
        }}
        onApprove={async(data, actions)=> {
            const order = await actions.order?.capture();
            props.onSuccess(order);
        }} 
        onCancel={async(data, actions) =>{
            if(props.onCancelled)
            props.onCancelled(data);
        }}
        />
    )
}
export default PaypalButton;